.loader-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  width: 100%; /* Adjust as needed */
  height: 100%; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: white; */
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
}
.activ {
  background-color: #181c32;
  border-radius: 5px;
  color: white;
}
.dropdown-toggle::after {
  display: none !important;
}
.dropdown ul {
  min-width: none !important;
  /* transform: translate3d(40px, 21.1429px, 0px) !important; */
}
.dropdown-menu ul {
  width: 10px !important;
}
.modal-l {
  min-width: 90% !important;
}
/* .react-datepicker__input-container input {
  height: 38px !important;
} */
.react-datepicker__close-icon {
  height: 38px !important;
}
.react-datepicker__close-icon::after {
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-datepicker-wrapper input {
  height: 38px !important;
  width: 100% !important;
  margin-bottom: 0 !important;
}
