.header_border_radius {
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}
/* .active {
  border-bottom: 2px solid grey;
  cursor: pointer;
} */
.active {
  cursor: pointer;
}

.non_active {
  cursor: pointer;
}
.del_add span {
  background: #22b14c;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.del_add a {
  display: inline-block;
  color: #fff;
  position: relative;
  top: -4px;
  font-weight: bold;
}
.menu-link a {
  text-decoration: none;
}
.form-control.form-control-solid {
  background-color: var(--bs-gray-100);
  border-color: var(--bs-gray-100);
  color: var(--bs-gray-700);
  transition: color 0.2s ease;
  height: 45px;
}
.table-responsive a {
  text-decoration: none;
}
.del_col_change span {
  background: #f55566 !important;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.del_add span {
  background: #22b14c;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.cards_filter {
  filter: drop-shadow(1px 1px 2px black);
}
.above_breadcrums {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.icon_size {
  font-size: 20px;
}
.display_none {
  display: none;
}
/* .logo_filter{
  filter: drop-shadow(10px 10px 10px blue);
} */
.searchbar_border {
  border: 0.5px solid black;

  width: 18%;
  border-radius: 8px;
  padding-bottom: 0px;
  padding-top: 0px;
  height: 33px;
  margin-bottom: 16px;
}
.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
}
.datepicker {
  border-radius: '8px';
  height: '33px';
  width: '190px';
  border: '0.3px solid black';
}

.react-datepicker-wrapper input {
  border: 0.5px solid black;
  width: 199px;
  border-radius: 8px;
  padding-bottom: 0px;
  padding-top: 0px;
  height: 33px;
  margin-bottom: 16px;
}
/* modal css */
/* Modal Overlay */
.modal-overlays {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Container */
.modals {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  max-width: 80%;
  min-width: 32%;
  min-height: 20%;
  padding-left: 24px;
  padding-top: 44px;
}

/* Close Button */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #888;
}
.display_block {
  display: block;
}
.display_none {
  display: none;
}

.upside-down {
  transform: rotate(180deg);
  transform-origin: center;
}
/* modal css */
/* Add this CSS to your stylesheet */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999; /* Ensure the modal appears on top of other elements */
}

.modal-contents {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 23%; /* Set the width to 20% */
  position: relative; /* Ensure positioning within the modal */
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.close:hover {
  color: red; /* Change the color on hover, adjust as desired */
}
.box_shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.my-container-styles {
  padding-left: 0;
  padding-right: 0;
}
.app-content {
  padding-bottom: 0px;
}
.width_class {
  --bs-app-sidebar-width: 240px;
}
.tBody {
  max-height: 40px !important;
  overflow: scroll !important;
}
.table-wrapper {
  overflow-x: auto;
  max-width: 100%;
}

.table-wrapper::-webkit-scrollbar {
  display: none;
}
.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Make sure it's above the content */
}

.myclass {
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.green {
  color: green;
}
.red {
  color: red;
}