.clientinfo-box {
    border: 1px solid rgb(214, 214, 214);
    padding: 20px;
    border-radius: 5px;
}

.inputrow {
    width: 100%;
    margin: auto;
}

.dynamic-input {
    width: 100%;
}

.inputrow {
    width: 100%;
    margin: auto;
}

/* .add {
    width: 5%;
    padding: 5px !important;
} */

.add i {
    vertical-align: middle;
    font-size: 25px;
}
.react-datepicker__close-icon {
    height: 73%;
}
.disabled {
  
    opacity: 0.6; /* Set opacity to visually indicate that the link is disabled */
    cursor: pointer; /* Set cursor to not-allowed to further indicate that the link is disabled */
  }