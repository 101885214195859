.profile_image {
    width: 80px;
    /* Adjust the width as needed */
    height: 80px;
    /* Adjust the height as needed */
    overflow: hidden;
    border-radius: 50%;
}

.profile_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile_image_profile {
    width: 60px;
    /* Adjust the width as needed */
    height: 60px;
    /* Adjust the height as needed */
    overflow: hidden;
    border-radius: 50%;
}

.profile_image_profile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app-navbar {
    margin-right: 80px;
    padding-inline: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-end;
}

#kt_app_sidebar_toggle {
    cursor: pointer;
}